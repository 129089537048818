import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import { contactDetails } from '../data/data';
import loadable from '@loadable/component';

import '../styles/contacts.scss';

const Grid = loadable(() => import('../components/grid'));
const Mobile = loadable(() => import('../components/icons/mobile'));
const Email = loadable(() => import('../components/icons/email'));
const Address = loadable(() => import('../components/icons/address'));

const Contacts = ({ data }) => {
  const contactImages = data.allFile.edges.map(item => item.node.childImageSharp.fluid);

  const metaData = {
    title: 'Контакти'
  };

  return (
    <Layout metaData={metaData}>
      <Grid>
        {contactDetails.map((arch, i) => (
          <div key={i}>
            <Img fluid={contactImages[i]} loading="eager" fadeIn={false}></Img>
            <section className="contacts-content">
              <h2>{arch.name}</h2>
              <p>
                <Mobile />
                <a href={`mailto:${arch.mobile}`}>{arch.mobile}</a>
              </p>
              <p>
                <Email />
                <a href={`mailto:${arch.email}`}>{arch.email}</a>
              </p>
              <p>
                <Address />
                {arch.address}
              </p>
            </section>
          </div>
        ))}
      </Grid>
    </Layout>
  );
};

export default Contacts;

export const query = graphql`
  query contacts {
    allFile(sort: { fields: name }, filter: { relativePath: { regex: "/images/personal/profiles/" } }) {
      edges {
        node {
          id
          ...mediumImage
        }
      }
    }
  }
`;
